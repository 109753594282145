import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/actions";
import styles from './Home.module.css'
import stylesTag from '../../components/UI/Tag/Tags.module.css';
import LogoMax from "../../components/Logo/LogoMax";
import Button from '../../components/UI/Button/Button';
import Tag from "../../components/UI/Tag/Tag";


const Home = () => {

    const [fields, setFields] = useState({
        title: "Sr Software Engineer",
        roles: "Game Developer / Web Frontend Developer / Web Backend Developer"
    });

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);


    const homeEffect = useSelector(state => {
        return state.homeEffect;
    });
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    const [entryAnimation, setEntryAnimation] = useState(false);

    let classesTextZone = [styles.TextZone];
    let classesButton = [styles.ButtonAnimation];

    if (homeEffect) {
        classesTextZone.push(styles.TextZoneAnim);
        classesButton.push(styles.ButtonHomeEffect)
    } else
        classesTextZone = [styles.TextZone];

    const triggerEntryLandingTimer = () => {
        setTimeout(() => {
            setEntryAnimation(true);
            setTimeout(() => {
                setEntryAnimation(false);
            }, 3000)
        }, 3000)
    }

    useEffect(() => {

        triggerEntryLandingTimer();
        if (routeTransition) {
            clearTimeout(triggerEntryLandingTimer);
        }
        return () => {
            setEntryAnimation(false)
        }

    }, [routeTransition])

    useEffect(() => {
        const graphqlQuery = {
            query: `
            {
            generalInfoData{
                title
                roles
            }}
            `
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then(data => {

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }

            // Batch updates to fields state
            setFields({
                title: data.data.generalInfoData.title,
                roles: data.data.generalInfoData.roles
            });

        }).catch(err => {
            // console.log(err);
        })

    }, [addFeedbackMessage]);


    const pageContent = <React.Fragment>
        <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
        <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
        <div className={styles.HomeContainer}>
            <div className={classesTextZone.join(' ')}>
                <h1>Hi,<br />
                    I'm <span className={styles.ZCharacter} style={{ color: "#08fdd8" }}>Z</span>ied
                    Sioud, <br />
                    {fields["title"]}</h1>
                <h2>{fields["roles"]}</h2>
                <Button link="/contactMe" classes={classesButton.join(' ')}>CONTACT ME</Button>
            </div>
            <div className={styles.LogoZone}>
                <LogoMax playEntryAnimation={entryAnimation} />
            </div>
        </div>
        <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
        <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
    </React.Fragment>

    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/" ? pageContent : null : pageContent
            }
        </React.Fragment>
    );
}

export default Home;